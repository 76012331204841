import { format } from "date-fns";
export function toPrice(cents: number, currency: String) {
  return `${(cents / 100).toFixed(2)} ${currency}`;
}

export const createReplacer = (replaceObject) => (string) => {
  return Object.entries(replaceObject).reduce((previousReturn, [replaceKey, replaceValue]) => {
    const replacer = new RegExp(`{{${replaceKey}}}`, "g");
    return previousReturn.replace(replacer, replaceValue);
  }, string);
};

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function padLeft(input: number | string, amount: number, padWith: string = "0") {
  return Array(amount - String(input).length + 1).join(padWith || "0") + input;
}

export function UTCtoLocaleTimezone(dateString: string) {
  return dateString.replace("+00:00", "").replace("Z", "") + "+00:00";
}

export function formatDate(dateString: string) {
  return format(new Date(UTCtoLocaleTimezone(dateString)), "dd.MM.yyyy\xa0HH:mm");
}

export function sumLineitemsWithoutDiscount(lineItems) {
  return lineItems.reduce((acc, cur) => (cur.articleNumber !== "W13001" ? acc + cur.price : acc), 0);
}

export function sumLineitemsWithDiscount(lineItems) {
  return lineItems.reduce((acc, cur) => acc + cur.price, 0);
}

export const scrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute("style", "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;");
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

export const ISOCountryToText = (isocode: string) => {
  switch (isocode) {
    case "DE":
      return "Deutschland";
    case "CH":
      return "Schweiz";
    case "AT":
      return "Österreich";
    case "LI":
      return "Liechtenstein";
    default:
      return isocode;
  }
};

export function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
